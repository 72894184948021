<template>
  <div>
    <template v-if="!other.isDone">
      <div class="flex flex-col gap-y-3">
        <div class="flex flex-col gap-y-2">
          <h1 class="font-EffraM text-2xl text-black">Téléchargez votre CIN</h1>
          <span style="color: #767676" class="text-lg font-EffraR"
            >Pour éviter toute usurpation d'identité et vous garantir un niveau
            de sécurité élevé, merci de télécharger le document demandé.</span
          >
        </div>
        <div
          class="
            flex
            justify-center
            items-center
            border-2 border-dashed
            h-28
            font-EffraR
            text-lg
            rounded-cu
            relative
            
          "
          :class="customClass"
          style="border-color: #767676; color: #767676"
        >
          <div
            class="flex justify-center gap-x-2 items-center"
            v-if="!other.startUpload"
          >
            <input
              type="file"
              class="
                absolute
                top-0
                left-0
                h-full
                w-full
                opacity-0
                cursor-pointer
                bg-rosDok-ultra
              "
              @change="onUpload"
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="43"
              height="43"
              fill="none"
              viewBox="0 0 43 43"
            >
              <path
                stroke="#AAA"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="3"
                d="M25.084 3.583H10.75c-.95 0-1.861.378-2.533 1.05-.672.672-1.05 1.583-1.05 2.534v28.666c0 .95.378 1.862 1.05 2.534.672.672 1.583 1.05 2.533 1.05h21.5c.95 0 1.862-.378 2.534-1.05.672-.672 1.05-1.583 1.05-2.534v-21.5l-10.75-10.75z"
              />
              <path
                stroke="#AAA"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="3"
                d="M25.083 3.583v10.75h10.75M21.5 32.25V21.5M16.125 26.875h10.75"
              />
            </svg>

            Cliquez ici pour choisir un fichier
          </div>
          <div
            class="flex flex-col gap-y-2 w-full px-2"
            v-if="other.startUpload"
          >
            <h1 class="font-EffraR text-lg text-black">
              {{ file.name || "--" }}
            </h1>
            <div
              id="line"
              class="
                h-2
                bg-rosDok-light
                w-full
                rounded
                relative
                overflow-hidden
              "
            >
              <div
                id="progress"
                class="h-2 bg-rosDok-ultra absolute left-0 top-0"
                :style="`width: ${other.percentage}%;`"
              ></div>
            </div>
            <span style="color: #767676" v-if="other.percentage < 99"
              >En cours...</span
            >
            <span
              v-if="other.percentage === 100"
              class="text-lg font-EffraR flex items-center gap-x-2"
              style="color: #30971f"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="#30971F"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M22 11.08V12c-.001 2.156-.7 4.255-1.99 5.982-1.292 1.727-3.107 2.99-5.175 3.602-2.068.611-4.278.538-6.3-.21-2.023-.747-3.75-2.128-4.924-3.937-1.174-1.809-1.731-3.949-1.59-6.1.143-2.152.976-4.2 2.377-5.84C5.8 3.857 7.693 2.715 9.796 2.24c2.104-.475 4.304-.258 6.274.62"
                />
                <path
                  stroke="#30971F"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M22 4L12 14.01l-3-3"
                />
              </svg>
              Succès
            </span>
          </div>
        </div>
        <div class="bg-dokBlue-lighter p-3 flex flex-col gap-y-2">
          <h5 class="flex items-center font-EffraM text-black gap-x-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 16 16"
            >
              <g clip-path="url(#clip0)">
                <path
                  stroke="#111"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M8 14.667c3.682 0 6.666-2.985 6.666-6.667S11.682 1.333 8 1.333C4.318 1.333 1.333 4.318 1.333 8S4.318 14.667 8 14.667zM8 10.667V8M8 5.333h.007"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <path fill="#fff" d="M0 0H16V16H0z" />
                </clipPath>
              </defs>
            </svg>
            Instructions
          </h5>

          <span class="font-EffraR text-black">
            Les fichiers doivent être en png, jpeg <br />
            L'image doit être de haute qualité, claire et lisible.
          </span>
        </div>
      </div>
      <div class="flex justify-end mt-5" v-if="other.next">
        <button
          class="
            bg-rosDok-ultra
            border-0
            py-3
            px-11
            rounded-cu
            text-white
            font-EffraM
            text-lg
            uppercase
            cursor-pointer
          "
          @click="lastStep"
        >
          Continuer
        </button>
      </div>
    </template>
    <!-- Done File Uploaded -->
    <template v-if="other.isDone">
      <div class="flex flex-col gap-y-10 mb-8">
        <div class="flex flex-col gap-y-3 items-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="112"
            height="112"
            fill="none"
            viewBox="0 0 112 112"
          >
            <path
              stroke="#30971F"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="12"
              d="M102.667 51.707V56c-.005 10.063-3.264 19.855-9.29 27.915-6.025 8.06-14.494 13.957-24.145 16.81-9.65 2.853-19.964 2.511-29.404-.977-9.44-3.487-17.499-9.933-22.976-18.375s-8.079-18.429-7.417-28.47c.662-10.042 4.553-19.6 11.091-27.25 6.538-7.65 15.374-12.981 25.19-15.199s20.086-1.203 29.278 2.893"
            />
            <path
              stroke="#30971F"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="12"
              d="M102.667 18.667L56 65.38l-14-14"
            />
          </svg>

          <h1 class="text-2xl font-EffraM text-black">
            CIN téléchargée avec succès
          </h1>

          <span style="color: #616161" class="text-lg font-EffraR">
            Vous recevrez un SMS dès que votre document sera vérifié
          </span>
        </div>
        <div
          class="bg-white shadow rounded-cu p-5 flex flex-col gap-y-3"
          v-if="file"
        >
          <div class="w-full grid grid-cols-2 flex-row gap-x-2">
            <label class="w-full font-EffraR" style="color: #6d6d6d"
              >Statut</label
            >
            <span
              class="font-EffraR flex items-center gap-x-1"
              style="color: #ae851a"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="none"
                viewBox="0 0 18 18"
              >
                <path
                  stroke="#AE851A"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 1.5v3M9 13.5v3M3.697 3.698L5.82 5.82M12.18 12.18l2.122 2.122M1.5 9h3M13.5 9h3M3.697 14.302L5.82 12.18M12.18 5.82l2.122-2.122"
                />
              </svg>
              En attente de confirmation</span
            >
          </div>
          <div class="w-full grid grid-cols-2 flex-row gap-x-2">
            <label class="w-full font-EffraR" style="color: #6d6d6d"
              >Nom du ficher</label
            >
            <span class="font-EffraR text-black break-words">{{
              file.name
            }}</span>
          </div>
          <div class="w-full grid grid-cols-2 flex-row gap-x-2">
            <label class="w-full font-EffraR" style="color: #6d6d6d"
              >Date et heure</label
            >
            <span class="font-EffraR text-black">{{
              _moment()(file.created_at).format("HH:mm - DD MMM YYYY")
            }}</span>
          </div>
        </div>
      </div>
      <div class="flex justify-center">
        <button
          class="
            bg-rosDok-ultra
            h-12
            px-10
            text-white
            border-0
            font-EffraM
            text-xl
            uppercase
            rounded-cu
            cursor-pointer
          "
          @click="
            () => {
              this.$router.push({ name: 'doctorsList' });
            }
          "
        >
          Mon compte
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { EventBus } from "@/event-bus";
import moment from "moment";

export default {
  props: {
    customClass: {
      required: false,
      type: String
    },
    token: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      file: null,
      other: {
        percentage: 0,
        startUpload: false,
        isDone: false,
        next: false
      }
    };
  },
  created() {
    this.fetchFiles();
  },
  mounted() {
    EventBus.$on("CountProgressUpload", percentage => {
      this.other.percentage = percentage;
    });
  },
  methods: {
    ...mapActions({
      uploadIdentityDocument: "file/UPLOAD_IDENTITY_DOCUMENT",
      myFiles: "file/GET_IDENTITY_DOCUMENT"
    }),
    _moment() {
      return moment;
    },
    fetchFiles() {
      this.myFiles({
        token: this.token,
        onData: ({ data, ok }) => {
          if (ok && data[0]) {
            this.file = data[0];
            this.other.isDone = true;
            EventBus.$emit("Delete_bg_from_parent");
          }
        }
      });
    },
    lastStep() {
      EventBus.$emit("Delete_bg_from_parent");
      this.other.isDone = true;
    },
    async onUpload() {
      if (event.target.files[0]) {
        this.file = event.target.files[0];
        this.other.startUpload = true;

        const fileUploadResponse = await this.uploadIdentityDocument({
          token: this.token,
          file: {
            type: "CIN",
            file: this.file
          }
        });

        if (fileUploadResponse === "Invalid Token") {
          return EventBus.$emit("flashDok", {
            type: "errorr",
            message: message
          });
        }

        if (fileUploadResponse.ok) {
          this.other.next = true;
        }
      }
    }
  }
};
</script>

<style>
</style>